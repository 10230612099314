<template>
    <div id="pageList" class="CustomerList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入客户名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">客户类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="pageList.queryParam.type" placeholder="请选择客户类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">省：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('provinceId',el)" v-model="pageList.queryParam.provinceId" placeholder="请选择省" clearable style="width:100%" @change="(val)=>{selectOnChange(val,'provinceId')}">
                            <el-option v-for="item in comboSelect({comboId:'provinceId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" placeholder="请选择市" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>

                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">联系人：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.contacts = validForbid(e)" placeholder="请输入联系人进行模糊匹配查询..." v-model="pageList.queryParam.contacts" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系地址：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.address = validForbid(e)" placeholder="请输入联系地址进行模糊匹配查询..." v-model="pageList.queryParam.address" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">联系电话：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.telephone = validForbid(e)" placeholder="请输入联系电话进行模糊匹配查询..." v-model="pageList.queryParam.telephone" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所属行业：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('industry',el)" v-model="pageList.queryParam.industry" placeholder="请选择所属行业" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'industry'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>

                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择客户状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="客户名称"/>
                <el-table-column prop="F_TYPE" label="客户类型"/>
                <el-table-column prop="F_PROVINCE_ID" label="省"/>
                <el-table-column prop="F_CITY_ID" label="市"/>
                <el-table-column prop="F_CONTACTS" label="联系人"/>
                <el-table-column prop="F_ADDRESS" label="联系地址"/>
                <el-table-column prop="F_TELEPHONE" label="联系电话"/>
                <el-table-column prop="F_INDUSTRY" label="所属行业"/>
                <el-table-column prop="F_STATUS" label="客户状态"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import CustomerCard from './CustomerCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CustomerList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                provinceData:[],
                cityData:[],
                pageList: {
                    queryParam: {
                        provinceId:"",
                        cityId:"",
                        name:"",
                        contacts:"",
                        address:"",
                        telephone:"",
                        industry:"",
                        type:"",
                        status:"",
                        flag:""
                    },
                    modelComp: CustomerCard,
                    modelMethod: "/customer/pageData"
                }
            })
            onMounted(async ()=>{
                await buildProvinceData();
            })
            const selectOnChange=async(newValue,selectId)=>{
                if('provinceId'==selectId){
                    dataObj.pageList.queryParam.cityId='';
                    await buildCityData(newValue);
                }
            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'type'==params.comboId){
                        return [{value:0,label:'个人'},{value:1,label:'机关单位'},{value:2,label:'国企'},{value:3,label:'民企'},{value:4,label:'外资'},{value:5,label:'合资'}]
                    }
                    if(params && 'industry'==params.comboId){
                        return [{value:0,label:'零售'},{value:1,label:'电商'},{value:2,label:'物流'},{value:3,label:'工业'},{value:4,label:'其它'}]
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'公海'},{value:1,label:'待签'},{value:2,label:'已签'},{value:3,label:'已解约'}]
                    }
                    if(params && 'provinceId'==params.comboId){
                        return dataObj.provinceData;
                    }
                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                }
            })
            const buildProvinceData=async ()=>{
                dataObj.provinceData= await utils.$$api.getProvinces({});//省
            }
            const buildCityData=async (provinceId)=>{
                dataObj.cityData = await utils.$$api.getCitiesByProvince({provinceId:provinceId});//市
            }
            return{
                ...toRefs(dataObj),comboSelect,selectOnChange
            }
        }
    });
</script>

<style scoped>
    .CustomerList{
        width: 100%;
    }
</style>